import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../services/api';

function Login() {
  const [form, setForm] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      // Armazena o token no localStorage
      localStorage.setItem('token', token);
      // Opcional: Remova o token da URL para segurança
      navigate('/select-instance', { replace: true });
    }
  }, [location, navigate]);

  const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await api.post('/auth/login', form);
      localStorage.setItem('token', res.data.token);
      navigate('/select-instance'); // Redirecionar para seleção de instância
    } catch (error) {
      setMessage(error.response?.data?.message || 'Erro ao fazer login.');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Login</h2>
      {message && <div className="alert alert-danger">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Email</label>
          <input 
            type="email" 
            name="email" 
            className="form-control" 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label>Senha</label>
          <input 
            type="password" 
            name="password" 
            className="form-control" 
            onChange={handleChange} 
            required 
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>
    </div>
  );
}

export default Login;
