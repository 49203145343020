// src/pages/MessageList.js

import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaFetcher from '../components/MediaFetcher'; // Implemente este componente conforme necessário
import ReactMarkdown from 'react-markdown';
import './MessageList.css';

function MessageList({ messages, user, loadMoreMessages, hasMoreMessages, isGroupChat }) {
  const messagesEndRef = useRef(null);

  // Scroll automático para a última mensagem recebida
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const renderMessage = (msg) => {
    // Determinar se a mensagem é do próprio usuário
    const isOwnMessage = msg.key?.fromMe ?? msg.fromMe ?? false;
    const messageClass = isOwnMessage ? 'own-message' : 'received-message';
    let messageContent;

    // Renderização de diferentes tipos de mensagem
    switch (msg.messageType) {
      case 'conversation':
        messageContent = (
          <ReactMarkdown className="message-text">
            {msg.message.conversation}
          </ReactMarkdown>
        );
        break;

      case 'imageMessage': {
        const {
          url: encImageUrl,
          caption,
          mediaKey,
          fileEncSha256,
          fileSha256,
          mimetype,
        } = msg.message.imageMessage;

        messageContent = (
          <div className="image-message">
            <MediaFetcher
              file={encImageUrl}
              mediaKey={mediaKey}
              fileEncSha256={fileEncSha256}
              fileSha256={fileSha256}
              mimetype={mimetype}
              alt={caption || 'Imagem recebida'}
            />
            {caption && (
              <ReactMarkdown className="message-caption">
                {caption}
              </ReactMarkdown>
            )}
          </div>
        );
        break;
      }

      case 'audioMessage': {
        const {
          url: encAudioUrl,
          seconds,
          mimetype,
          mediaKey,
          fileEncSha256,
          fileSha256,
        } = msg.message.audioMessage;

        messageContent = (
          <div className="audio-message">
            <MediaFetcher
              file={encAudioUrl}
              mediaKey={mediaKey}
              fileEncSha256={fileEncSha256}
              fileSha256={fileSha256}
              mimetype={mimetype}
            />
            {seconds && <span className="audio-duration">{seconds}"</span>}
          </div>
        );
        break;
      }

      case 'videoMessage': {
        const {
          url: encVideoUrl,
          seconds,
          mimetype,
          mediaKey,
          fileEncSha256,
          fileSha256,
        } = msg.message.videoMessage;

        messageContent = (
          <div className="video-message">
            <MediaFetcher
              file={encVideoUrl}
              mediaKey={mediaKey}
              fileEncSha256={fileEncSha256}
              fileSha256={fileSha256}
              mimetype={mimetype}
            />
            {seconds && <span className="video-duration">{seconds}"</span>}
          </div>
        );
        break;
      }

      case 'documentMessage': {
        const {
          url: encDocumentUrl,
          mimetype,
          mediaKey,
          fileEncSha256,
          fileSha256,
          fileName,
        } = msg.message.documentMessage;

        messageContent = (
          <div className="document-message">
            <MediaFetcher
              file={encDocumentUrl}
              mediaKey={mediaKey}
              fileEncSha256={fileEncSha256}
              fileSha256={fileSha256}
              mimetype={mimetype}
              alt={fileName || 'Documento recebido'}
            />
            {fileName && <p className="document-name">{fileName}</p>}
          </div>
        );
        break;
      }

      // Adicione aqui outros casos para diferentes tipos de mensagem, se necessário

      default:
        messageContent = <p>Tipo de mensagem não suportado</p>;
        break;
    }

    return (
      <div key={`${msg.id}_${msg.messageTimestamp}`} className={`message ${messageClass}`}>
        {isGroupChat && !isOwnMessage && (
          <div className="message-sender">
            {msg.pushName || 'Desconhecido'}
          </div>
        )}
        {messageContent}
        <span className="message-time">
          {new Date(msg.messageTimestamp * 1000).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      </div>
    );
  };

  return (
    <div className="message-list" id="scrollableDiv">
      <InfiniteScroll
        dataLength={messages.length}
        next={loadMoreMessages}
        hasMore={hasMoreMessages}
        inverse={true}
        loader={<h4>Carregando...</h4>}
        scrollableTarget="scrollableDiv"
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        {messages.map((msg) => renderMessage(msg))}
      </InfiniteScroll>
      <div ref={messagesEndRef} />
    </div>
  );
}

export default MessageList;
