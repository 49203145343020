import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://conversasback.enviobot.com.br/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Interceptor para adicionar cabeçalhos de autenticação e instanceId
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const instanceId = localStorage.getItem('instanceId');
    const instanceName = localStorage.getItem('instanceName');
    console.log('Token retrieved for request:', token); // Debug
    console.log('Instance ID retrieved for request:', instanceId); // Debug
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (instanceId) {
      config.headers['x-instance-id'] = instanceName;
      config.headers['x-instance-id2'] = instanceId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
