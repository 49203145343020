// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */
body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
  }
  
  h2, h5 {
    color: #333;
  }
  
  .list-group-item.active {
    background-color: #25d366;
    border-color: #25d366;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,SAAS;IACT,UAAU;IACV,4DAA4D;IAC5D,yBAAyB;EAC3B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;EACd","sourcesContent":["/* src/index.css */\r\nbody {\r\n    margin: 0;\r\n    padding: 0;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    background-color: #f0f2f5;\r\n  }\r\n  \r\n  h2, h5 {\r\n    color: #333;\r\n  }\r\n  \r\n  .list-group-item.active {\r\n    background-color: #25d366;\r\n    border-color: #25d366;\r\n    color: white;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
