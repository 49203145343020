// src/pages/Chat.js

import React, { useEffect, useState, useCallback, useRef } from 'react';
import api from '../services/api';
import ContactsList from './ContactsList';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import './Chat.css';
import { io } from 'socket.io-client';
// Importações para notificações
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Importações para o modal e ícones
import Modal from 'react-modal';
import { FaShareAlt } from 'react-icons/fa';

function Chat() {
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messagesPage, setMessagesPage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [contactsPage, setContactsPage] = useState(1);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [audioAllowed, setAudioAllowed] = useState(false); // Estado para gerenciar permissão de áudio
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); // Estado para controlar o modal de compartilhamento
  const [input, setInput] = useState(''); // Estado compartilhado para o campo de entrada

  const instanceId = localStorage.getItem('instanceId');
  const instanceName = localStorage.getItem('instanceName');

  const socketRef = useRef(null);

  // Referência para o contato selecionado
  const selectedContactRef = useRef(selectedContact);

  // Atualizar a referência sempre que selectedContact mudar
  useEffect(() => {
    selectedContactRef.current = selectedContact;
  }, [selectedContact]);

  // Referência para o áudio de notificação
  const notificationAudioRef = useRef(null);

  // Pré-carregar o áudio de notificação ao montar o componente
  useEffect(() => {
    notificationAudioRef.current = new Audio('https://enviobot.com.br/notificao.mp3');
    // Configurar propriedades do áudio
    notificationAudioRef.current.volume = 1.0; // Volume total
  }, []);

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const resUser = await api.get('/users/profile');
        setUser(resUser.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setLoading(false);
      }
    };

    fetchUser();

    // Solicitar permissão para notificações
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  // Initialize Socket.io connection (mantemos apenas para receber mensagens em tempo real)
  useEffect(() => {
    if (instanceId && instanceName) {
      initializeSocket();
    }

    // Clean up socket on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [instanceId, instanceName]);

  const initializeSocket = () => {
    socketRef.current = io('https://conversasback.enviobot.com.br', {
      auth: {
        token: localStorage.getItem('token'),
        instanceId: instanceId,
        instanceName: instanceName,
      },
    });

    socketRef.current.on('connect', () => {
      console.log('Conectado ao Socket.io');
    });

    socketRef.current.on('new_message', (message) => {
      console.log('Received new_message:', message);
      const remoteJid = message.key.remoteJid;
      const isGroup = remoteJid.endsWith('@g.us');
      const participantJid = isGroup ? message.key.participant : null;

      // Determinar o nome do participante ou grupo
      let participantName = '';
      if (isGroup) {
        participantName = message.groupName || remoteJid; // Utilize groupName se disponível
      } else {
        participantName = message.pushName || remoteJid;
      }

      // Log para verificar se o participantName está correto
      console.log(`Participant Name: ${participantName}`);

      // Atualizar mensagens se for o contato selecionado
      if (selectedContactRef.current && remoteJid === selectedContactRef.current.remoteJid) {
        console.log('Adicionando mensagem ao estado de mensagens');
        setMessages((prev) => [message, ...prev]);
        // Marcar como lida se não for mensagem enviada pelo próprio usuário
        if (!message.key.fromMe) {
          // Descomente a linha abaixo se quiser marcar como lida
          // api.post(`/contacts/${encodeURIComponent(remoteJid)}/read`);
        }
      }

      // Atualizar lista de contatos
      setContacts((prevContacts) => {
        const contactIndex = prevContacts.findIndex(
          (contact) => contact.remoteJid === remoteJid
        );

        if (contactIndex !== -1) {
          // Atualizar contato existente
          const updatedContacts = [...prevContacts];
          // Remover o contato da posição atual
          const [contact] = updatedContacts.splice(contactIndex, 1);
          const newUnreadMessages =
            (contact.unreadMessages || 0) + (message.key.fromMe ? 0 : 1);

          const updatedContact = {
            ...contact,
            lastMessage: extractMessageContent(message),
            unreadMessages: newUnreadMessages,
            lastMessageTimestamp: message.messageTimestamp,
            pushName: isGroup
              ? message.groupName || contact.pushName || contact.remoteJid // Atualize o pushName para grupos
              : contact.pushName || contact.remoteJid,
          };
          // Adicionar o contato atualizado no topo da lista
          updatedContacts.unshift(updatedContact);
          return updatedContacts;
        } else {
          // Adicionar novo contato
          const newContact = {
            id: remoteJid,
            remoteJid: remoteJid,
            formattedRemoteJid: remoteJid,
            pushName: isGroup
              ? message.groupName || remoteJid // Utilize groupName para grupos
              : participantName || remoteJid,
            isGroup: isGroup, // Adicionamos essa propriedade
            lastMessage: extractMessageContent(message),
            unreadMessages: message.key.fromMe ? 0 : 1,
            lastMessageTimestamp: message.messageTimestamp,
          };
          return [newContact, ...prevContacts];
        }
      });

      // Mostrar notificação se a mensagem não for do próprio usuário e não for do contato selecionado
      if (
        !message.key.fromMe &&
        (!selectedContactRef.current || remoteJid !== selectedContactRef.current.remoteJid)
      ) {
        showNotification(message, isGroup, participantName);
        // Reproduzir o áudio de notificação somente se o usuário permitiu
        if (audioAllowed && notificationAudioRef.current) {
          // Reiniciar o áudio se já estiver tocando
          notificationAudioRef.current.currentTime = 0;
          notificationAudioRef.current.play().catch((error) => {
            console.error('Erro ao reproduzir o áudio de notificação:', error);
          });
        }
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log('Desconectado do Socket.io');
    });

    socketRef.current.on('connect_error', (err) => {
      console.error('Erro de conexão do Socket.io:', err.message);
    });
  };

  // Função para extrair o conteúdo da mensagem
  const extractMessageContent = (message) => {
    const isGroup = message.key.remoteJid.endsWith('@g.us');
    const participantName = isGroup
      ? message.pushName || message.key.participant || ''
      : '';

    let content = '';

    switch (message.messageType) {
      case 'conversation':
        content = message.message.conversation;
        break;
      case 'imageMessage':
        content = message.message.imageMessage.caption || '[Imagem]';
        break;
      case 'audioMessage':
        content = '[Áudio]';
        break;
      case 'videoMessage':
        content = '[Vídeo]';
        break;
      // Adicione outros tipos de mensagem conforme necessário
      default:
        content = '[Mensagem]';
        break;
    }

    if (isGroup && participantName) {
      return `${participantName}: ${content}`;
    }

    return content;
  };

  // Função para exibir notificações visuais usando react-toastify
  const showNotification = (message, isGroup, participantName) => {
    // Formatar o título da notificação
    let title = '';
    if (isGroup) {
      const group = contacts.find(
        (contact) => contact.remoteJid === message.key.remoteJid
      );
      const groupName = group ? group.pushName : 'Grupo';
      title = participantName
        ? `${participantName} no ${groupName}`
        : groupName;
    } else {
      title = message.pushName || message.key.remoteJid;
    }

    // Formatar o conteúdo da notificação
    const body = extractMessageContent(message);

    // Exibir a notificação usando react-toastify
    toast.info(
      <div>
        <strong>{title}</strong>
        <div>{body}</div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 8000, // 8 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  // Função para buscar contatos com paginação
  const fetchContacts = useCallback(
    async (page = 1) => {
      try {
        const resContacts = await api.get('/contacts', {
          params: {
            page,
            limit: 20,
          },
        });
        if (resContacts.data.length > 0) {
          const formattedContacts = resContacts.data.map((contact) => {
            const isGroup = contact.remoteJid.endsWith('@g.us');
            return {
              ...contact,
              isGroup: isGroup,
              pushName: isGroup
                ? contact.groupName || contact.pushName || null
                : contact.pushName || null,
            };
          });
          

          if (page === 1) {
            setContacts(formattedContacts);
          } else {
            // Evitar duplicados ao carregar mais contatos
            setContacts((prev) => {
              const newContacts = formattedContacts.filter(
                (newContact) =>
                  !prev.some(
                    (contact) => contact.remoteJid === newContact.remoteJid
                  )
              );
              return [...prev, ...newContacts];
            });
          }
        } else {
          setHasMoreContacts(false);
        }
      } catch (error) {
        console.error('Erro ao carregar contatos:', error);
      }
    },
    []
  );

  // Buscar contatos na montagem do componente
  useEffect(() => {
    fetchContacts(1);
  }, [fetchContacts]);

  const loadMoreContacts = () => {
    if (hasMoreContacts) {
      const nextPage = contactsPage + 1;
      fetchContacts(nextPage);
      setContactsPage(nextPage);
    }
  };

  // Função para buscar mensagens com paginação
  const fetchMessages = useCallback(
    async (page = 1) => {
      if (!selectedContact) return;
      try {
        const resMessages = await api.get('/messages', {
          params: {
            page,
            limit: 20,
            remoteJid: selectedContact.remoteJid,
          },
        });

        if (resMessages.data.length > 0) {
          const processedMessages = resMessages.data.map((msg) => {
            // Assegurar que key.fromMe esteja definido
            if (!msg.key) {
              msg.key = {};
            }
            if (typeof msg.key.fromMe === 'undefined') {
              msg.key.fromMe = msg.fromMe ?? false;
            }
            return msg;
          });

          if (page === 1) {
            setMessages(processedMessages);
          } else {
            setMessages((prev) => [...prev, ...processedMessages]);
          }
        } else {
          setHasMoreMessages(false);
        }

        // Resetar unreadMessages para o contato selecionado
        // Descomente a linha abaixo se quiser marcar como lida
        // await api.post(`/contacts/${encodeURIComponent(selectedContact.remoteJid)}/read`);
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact.remoteJid === selectedContact.remoteJid
              ? { ...contact, unreadMessages: 0 }
              : contact
          )
        );
      } catch (error) {
        console.error('Erro ao carregar mensagens:', error);
      }
    },
    [selectedContact]
  );

  // Buscar mensagens quando o contato selecionado muda
  useEffect(() => {
    setMessages([]);
    setMessagesPage(1);
    setHasMoreMessages(true);
    if (selectedContact) {
      fetchMessages(1);
    }
  }, [selectedContact, fetchMessages]);

  const loadMoreMessages = () => {
    if (hasMoreMessages) {
      const nextPage = messagesPage + 1;
      fetchMessages(nextPage);
      setMessagesPage(nextPage);
    }
  };

  // Função para enviar mensagem via POST
  const sendMessage = async (text, mediaContent = null, mediaType = null) => {
    if (
      (text.trim() || mediaContent) &&
      selectedContact
    ) {
      try {
        // Determinar o tipo da mensagem
        let type = 'text';
        if (mediaType === 'image') type = 'anexo';
        else if (mediaType === 'video') type = 'video';
        else if (mediaType === 'audio') type = 'audio';
        else if (mediaType === 'pdf') type = 'pdf';

        // Construir o payload para a requisição
        const payload = {
          remoteJid: selectedContact.remoteJid,
          content: mediaContent || text,
          type: type,
        };

        // Enviar a requisição POST para o backend
        const response = await api.post('/messages/send', payload);

        if (response.status === 200) {
          const responseData = response.data.data;
          const sentMediaType = response.data.mediaType;

          //toast.success('Mensagem enviada com sucesso.');

          // Adicionar a mensagem à lista de mensagens no estado
          const newMessage = {
            id: responseData.id || Date.now().toString(), // Utilize o ID retornado pelo backend, se disponível
            key: { fromMe: true, remoteJid: selectedContact.remoteJid },
            messageType: sentMediaType === 'Texto' ? 'conversation' : sentMediaType.toLowerCase(),
            message: sentMediaType === 'Texto'
              ? { conversation: text }
              : sentMediaType === 'Anexo'
                ? { imageMessage: { caption: text, mimetype: 'image/png', url: mediaContent } }
                : sentMediaType === 'Áudio'
                  ? { audioMessage: { url: mediaContent, mimetype: 'audio/mpeg' } }
                  : sentMediaType === 'PDF'
                    ? { documentMessage: { caption: text, mimetype: 'application/pdf', url: mediaContent } }
                    : sentMediaType === 'Vídeo'
                      ? { videoMessage: { caption: text, mimetype: 'video/mp4', url: mediaContent } }
                      : { conversation: text }, // Fallback para tipos desconhecidos
            messageTimestamp: Math.floor(Date.now() / 1000),
            pushName: user.name || user.email,
            participant: user.jid || '', // Certifique-se de que o 'user' tenha a propriedade 'jid'
          };
          //setMessages((prev) => [newMessage, ...prev]);
          console.log('Mensagem adicionada ao estado de mensagens:', newMessage);

          // Atualizar o contato na lista de contatos
          setContacts((prevContacts) => {
            const contactIndex = prevContacts.findIndex(
              (contact) => contact.remoteJid === selectedContact.remoteJid
            );

            if (contactIndex !== -1) {
              const updatedContacts = [...prevContacts];
              // Remover o contato da posição atual
              const [contact] = updatedContacts.splice(contactIndex, 1);

              const updatedContact = {
                ...contact,
                lastMessage: extractMessageContent(newMessage),
                lastMessageTimestamp: newMessage.messageTimestamp,
                // Não incrementa unreadMessages se for mensagem enviada por você
              };
              // Adicionar o contato atualizado no topo da lista
              updatedContacts.unshift(updatedContact);
              return updatedContacts;
            } else {
              // Caso o contato não exista na lista, adicionamos
              const newContact = {
                id: selectedContact.remoteJid,
                remoteJid: selectedContact.remoteJid,
                formattedRemoteJid: selectedContact.remoteJid,
                pushName: selectedContact.pushName || selectedContact.remoteJid,
                isGroup: selectedContact.isGroup,
                lastMessage: extractMessageContent(newMessage),
                unreadMessages: 0,
                lastMessageTimestamp: newMessage.messageTimestamp,
              };
              return [newContact, ...prevContacts];
            }
          });
        } else {
          toast.error('Falha ao enviar a mensagem.');
        }
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error.response?.data || error.message);
        toast.error('Falha ao enviar a mensagem. Tente novamente.');
      }
    } else {
      toast.error('Preencha o campo de mensagem ou selecione uma mídia.');
    }
  };

  // Função auxiliar para obter o mimetype baseado no tipo de mídia
  const getMimeType = (mediaType) => {
    switch (mediaType) {
      case 'image':
        return 'image/png'; // Ajuste conforme necessário
      case 'video':
        return 'video/mp4';
      case 'audio':
        return 'audio/mpeg';
      case 'pdf':
        return 'application/pdf';
      default:
        return '';
    }
  };

  // Função para permitir áudio após interação do usuário
  const handleEnableAudio = () => {
    if (notificationAudioRef.current) {
      notificationAudioRef.current
        .play()
        .then(() => {
          setAudioAllowed(true);
          // Pausar imediatamente após desbloquear para evitar reprodução automática
          notificationAudioRef.current.pause();
          notificationAudioRef.current.currentTime = 0;
        })
        .catch((error) => {
          console.error('Erro ao tentar desbloquear áudio:', error);
        });
    }
  };

  // Função para abrir o modal de compartilhamento
  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  // Função para fechar o modal de compartilhamento
  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  // Função para lidar com a seleção de contato para compartilhamento
  const handleShareContact = (contact) => {
    // Insira o número do contato no campo de entrada de mensagem
    if (contact) {
      const contactNumber = getPhoneNumber(contact.remoteJid);
      setInput(`Contato: ${contactNumber}`); // Atualiza o estado de input
      closeShareModal();
      toast.success(`Contato ${contactNumber} compartilhado!`);
    }
  };

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    );
  }

  const isGroupChat = selectedContact ? selectedContact.isGroup : false;
  const getPhoneNumber = (remoteJid) => {
    if (!remoteJid) return '';
    return remoteJid.split('@')[0];
  };

  return (
    <div className="chat-container d-flex">
      <ContactsList
        contacts={contacts}
        selectedContact={selectedContact}
        onSelectContact={setSelectedContact}
        loadMoreContacts={loadMoreContacts}
        hasMoreContacts={hasMoreContacts}
      />
      <div className="chat-area flex-grow-1 d-flex flex-column">
        {!audioAllowed && (
          <div className="audio-permission-overlay">
            <div className="audio-permission-content">
              <p>Para receber notificações sonoras, clique no botão abaixo:</p>
              <button className="btn btn-primary" onClick={handleEnableAudio}>
                Ativar Som de Notificação
              </button>
            </div>
          </div>
        )}
        {selectedContact ? (
          <>
            <div className="chat-header p-3 border-bottom d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={
                    selectedContact.profilePicUrl ||
                    `https://ui-avatars.com/api/?name=${encodeURIComponent(
                      selectedContact.pushName || selectedContact.remoteJid
                    )}&background=random`
                  }
                  alt={selectedContact.pushName || selectedContact.remoteJid}
                  className="chat-header-avatar"
                />
                <div className="ms-3">
                  <h5 className="mb-0">
                    {selectedContact.pushName || selectedContact.formattedRemoteJid}
                    {isGroupChat && (
                      <span className="badge bg-secondary ms-2">Grupo</span>
                    )}
                  </h5>
                  {/* Exibir o número do contato se não for grupo */}
                  {!isGroupChat && (
                    <small className="text-muted">
                      {getPhoneNumber(selectedContact.remoteJid)}
                    </small>
                  )}
                </div>
              </div>
              {/* Botão de compartilhar contato */}
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={openShareModal}
                title="Compartilhar Contato"
              >
                <FaShareAlt />
              </button>
            </div>
            <MessageList
              messages={messages}
              user={user}
              loadMoreMessages={loadMoreMessages}
              hasMoreMessages={hasMoreMessages}
              isGroupChat={isGroupChat} // Passar a flag para MessageList
            />
            {/* Passar o número do destinatário, input e setInput como props */}
            <MessageInput
              onSend={sendMessage}
              phoneNumber={getPhoneNumber(selectedContact.remoteJid)}
              input={input}
              setInput={setInput}
            />
          </>
        ) : (
          <div className="no-chat d-flex align-items-center justify-content-center flex-grow-1">
            <h5>Selecione um contato para começar a conversar</h5>
          </div>
        )}
      </div>
      {/* Modal para compartilhar contato */}
      <Modal
        isOpen={isShareModalOpen}
        onRequestClose={closeShareModal}
        contentLabel="Compartilhar Contato"
        ariaHideApp={false}
        className="modal-dialog"
        overlayClassName="modal-backdrop"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Compartilhar Contato</h5>
            <button type="button" className="btn-close" onClick={closeShareModal}></button>
          </div>
          <div className="modal-body">
            <ContactsList
              contacts={contacts}
              selectedContact={selectedContact}
              onSelectContact={(contact) => handleShareContact(contact)}
              loadMoreContacts={loadMoreContacts}
              hasMoreContacts={hasMoreContacts}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeShareModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>
      {/* Container para as notificações */}
      <ToastContainer />
    </div>
  );
}

export default Chat;
