// src/pages/SelectInstance.js
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

function SelectInstance() {
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const res = await api.get('/instances');
        setInstances(res.data);
        setLoading(false);
      } catch (err) {
        console.error('Erro ao buscar instâncias:', err);
        setError('Erro ao buscar instâncias.');
        setLoading(false);
      }
    };

    fetchInstances();
  }, []);

  const handleSelect = (instance) => {
    console.log('Selected Instance:', instance); // Debug
    // Armazenar no localStorage
    localStorage.setItem('instanceId', instance.id);
    localStorage.setItem('instanceName', instance.name);
    
    // Verificar armazenamento
    console.log('Instance ID armazenado:', localStorage.getItem('instanceId'));
    console.log('Instance Name armazenado:', localStorage.getItem('instanceName'));

    navigate('/chat');
  };

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  if (instances.length === 0) {
    return (
      <div className="container mt-5">
        <h3>Nenhuma instância encontrada.</h3>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h3>Selecione uma instância conectada</h3>
      <div className="list-group">
        {instances.map((instance) => (
          <button
            key={instance.id}
            className="list-group-item list-group-item-action"
            onClick={() => handleSelect(instance)}
          >
            {instance.name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SelectInstance;
