// src/pages/MessageInput.js

import React, { useState, useRef, useEffect } from 'react';
import './MessageInput.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FaImage,
  FaVideo,
  FaMusic,
  FaFilePdf,
  FaMicrophone,
  FaStop,
  FaTimes,
} from 'react-icons/fa';
import api from '../services/api';
import PropTypes from 'prop-types';

function MessageInput({ onSend, phoneNumber, input, setInput }) { // Recebe input e setInput como props
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  // Estados para gravação de áudio
  const [isRecording, setIsRecording] = useState(false);
  const [recordTime, setRecordTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  const handleSend = async () => {
    if (input.trim() || media) {
      try {
        setIsUploading(true);
        let mediaContent = null;
        let sentMediaType = null;

        if (media) {
          const formData = new FormData();
          formData.append('file', media);
          formData.append('type', mediaType);
          formData.append('caption', input.trim());
          formData.append('number', phoneNumber); // Adiciona o número do destinatário

          const response = await api.post('/media/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(percentCompleted);
            },
          });

          mediaContent = response.data.mediaUrl;
          sentMediaType = mediaType;
        }

        // Enviar a mensagem com ou sem mídia
        await onSend(input.trim(), mediaContent, sentMediaType);

        // Resetar os estados
        setInput(''); // Atualiza o input via setInput
        setMedia(null);
        setMediaType(null);
        setUploadProgress(0);
        toast.success('Mensagem enviada com sucesso.');
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error);
        toast.error('Falha ao enviar a mensagem. Tente novamente.');
      } finally {
        setIsUploading(false);
      }
    } else {
      toast.error('Preencha o campo de mensagem ou selecione uma mídia.');
    }
  };

  const handleMediaSelect = (type) => {
    setMediaType(type);
    document.getElementById('mediaInput').click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia(file);
      toast.info(`Mídia ${file.type.startsWith('image/') ? 'imagem' : file.type.startsWith('video/') ? 'vídeo' : file.type.startsWith('audio/') ? 'áudio' : 'documento'} selecionada.`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  // Funções para gravação de áudio
  const handleStartRecording = async () => {
    if (isRecording) return;

    // Solicitar permissão para acessar o microfone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];
      setRecordTime(0);

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/ogg; codecs=opus' });
        const audioFile = new File([audioBlob], `audio_${Date.now()}.ogg`, { type: 'audio/ogg; codecs=opus' });
        setMedia(audioFile);
        setMediaType('audio');
        setRecordTime(0);
        toast.info('Áudio gravado e pronto para enviar.');
      };

      mediaRecorder.start();
      setIsRecording(true);
      toast.info('Gravação iniciada.');

      // Iniciar o timer
      timerRef.current = setInterval(() => {
        setRecordTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error('Erro ao acessar o microfone:', error);
      toast.error('Não foi possível acessar o microfone.');
    }
  };

  const handleStopRecording = () => {
    if (!isRecording) return;

    mediaRecorderRef.current.stop();
    setIsRecording(false);
    toast.success('Gravação parada.');

    // Parar o timer
    clearInterval(timerRef.current);
  };

  // Formatação do tempo
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${mins}:${secs}`;
  };

  // Função para remover a mídia selecionada
  const handleRemoveMedia = () => {
    setMedia(null);
    setMediaType(null);
    toast.info('Mídia removida.');
  };

  return (
    <div className="message-input-container">
      {media && (
        <div className="media-preview">
          <div className="media-preview-content">
            {mediaType === 'image' && (
              <img
                src={URL.createObjectURL(media)}
                alt="Pré-visualização"
                className="media-preview-image"
              />
            )}
            {mediaType === 'video' && (
              <video
                src={URL.createObjectURL(media)}
                controls
                className="media-preview-video"
              />
            )}
            {mediaType === 'audio' && (
              <audio
                src={URL.createObjectURL(media)}
                controls
                className="media-preview-audio"
              />
            )}
            {mediaType === 'pdf' && (
              <div className="media-preview-pdf">
                <FaFilePdf className="pdf-icon" />
                <span>{media.name}</span>
              </div>
            )}
            <FaTimes className="remove-media-icon" onClick={handleRemoveMedia} title="Remover Mídia" />
          </div>
        </div>
      )}
      <div className="message-input d-flex p-3 border-top">
        <input
          type="text"
          className="form-control me-2"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Digite sua mensagem..."
          disabled={isUploading || isRecording}
        />
        <div className="media-icons d-flex align-items-center me-2">
          <FaImage
            className="media-icon me-2"
            onClick={() => handleMediaSelect('image')}
            title="Enviar Imagem"
          />
          <FaVideo
            className="media-icon me-2"
            onClick={() => handleMediaSelect('video')}
            title="Enviar Vídeo"
          />
          <FaMusic
            className="media-icon me-2"
            onClick={() => handleMediaSelect('audio')}
            title="Enviar Áudio"
          />
          <FaFilePdf
            className="media-icon"
            onClick={() => handleMediaSelect('pdf')}
            title="Enviar PDF"
          />
          {/* Botões de gravação de áudio */}
          {!isRecording ? (
            <FaMicrophone
              className="media-icon ms-3 text-danger"
              onClick={handleStartRecording}
              title="Gravar Áudio"
            />
          ) : (
            <FaStop
              className="media-icon ms-3 text-warning"
              onClick={handleStopRecording}
              title="Parar Gravação"
            />
          )}
        </div>
        <button className="btn btn-success send-button" onClick={handleSend} disabled={isUploading || isRecording}>
          <i className="bi bi-send"></i>
        </button>
        <input
          type="file"
          id="mediaInput"
          style={{ display: 'none' }}
          accept={
            mediaType === 'image'
              ? 'image/*'
              : mediaType === 'video'
              ? 'video/*'
              : mediaType === 'audio'
              ? 'audio/*'
              : mediaType === 'pdf'
              ? 'application/pdf'
              : '*/*'
          }
          onChange={handleFileChange}
        />
        {isUploading && (
          <div className="upload-progress ms-3">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${uploadProgress}%` }}
              aria-valuenow={uploadProgress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {uploadProgress}%
            </div>
          </div>
        )}
        {isRecording && (
          <div className="recording-timer">
            <span>{formatTime(recordTime)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

MessageInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired, // Define o tipo da prop
  input: PropTypes.string.isRequired, // Recebe input como prop
  setInput: PropTypes.func.isRequired, // Recebe setInput como prop
};

export default MessageInput;
