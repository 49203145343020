// src/components/MediaFetcher.js

import React, { useEffect, useState } from 'react';
import api from '../services/api';
import PropTypes from 'prop-types';

function MediaFetcher({ file, mediaKey, fileEncSha256, fileSha256, mimetype, alt }) {
  const [mediaUrl, setMediaUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    let blobUrl = null;

    const fetchMedia = async () => {
      try {
        // Construir a URL para descriptografar a mídia
        const params = new URLSearchParams({
          file: file,
          mediaKey: mediaKey,
          fileEncSha256: fileEncSha256,
          fileSha256: fileSha256,
          mimetype: mimetype,
        });

        const response = await api.get(`/media/decrypt?${params.toString()}`, {
          responseType: 'blob',
        });

        blobUrl = URL.createObjectURL(response.data);

        if (isMounted) {
          setMediaUrl(blobUrl);
          setIsLoading(false);
        }
      } catch (err) {
        console.error('Erro ao buscar mídia:', err);
        if (isMounted) {
          setError('Erro ao carregar mídia.');
          setIsLoading(false);
        }
      }
    };

    fetchMedia();

    return () => {
      isMounted = false;
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [file, mediaKey, fileEncSha256, fileSha256, mimetype]);

  if (isLoading) return <p>Carregando mídia...</p>;
  if (error) return <p>{error}</p>;

  const renderMedia = () => {
    const cleanMimetype = mimetype.split(';')[0].trim();

    if (cleanMimetype.startsWith('image/')) {
      return <img src={mediaUrl} alt={alt} className="message-image" />;
    }

    if (cleanMimetype.startsWith('audio/')) {
      return (
        <audio controls>
          <source src={mediaUrl} type={cleanMimetype} />
          Seu navegador não suporta o elemento de áudio.
        </audio>
      );
    }

    if (cleanMimetype.startsWith('video/')) {
      return (
        <video controls className="message-video">
          <source src={mediaUrl} type={cleanMimetype} />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      );
    }

    if (cleanMimetype.startsWith('application/')) {
      return (
        <a href={mediaUrl} target="_blank" rel="noopener noreferrer" className="document-link">
          {alt || 'Abrir Documento'}
        </a>
      );
    }

    return <p>Tipo de mídia não suportado</p>;
  };

  return <div className="media-container">{renderMedia()}</div>;
}

MediaFetcher.propTypes = {
  file: PropTypes.string.isRequired,
  mediaKey: PropTypes.string,
  fileEncSha256: PropTypes.string,
  fileSha256: PropTypes.string,
  mimetype: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

MediaFetcher.defaultProps = {
  mediaKey: '',
  fileEncSha256: '',
  fileSha256: '',
  alt: 'Mídia recebida',
};

export default MediaFetcher;
