// src/ToggleContext.js
import React, { createContext, useState } from 'react';

// Criação do contexto
export const ToggleContext = createContext();

// Provider para envolver a aplicação
export const ToggleProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <ToggleContext.Provider value={{ toggle, setToggle }}>
      {children}
    </ToggleContext.Provider>
  );
};
