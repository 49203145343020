// src/pages/Register.js
import React, { useState } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

function Register() {
  const [form, setForm] = useState({ email: '', password: '', role: 'user' });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await api.post('/auth/register', form);
      setMessage(res.data.message);
      // Opcional: redirecionar para login
      // navigate('/login');
    } catch (error) {
      setMessage(error.response?.data?.message || 'Erro ao registrar.');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Registrar</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Email</label>
          <input 
            type="email" 
            name="email" 
            className="form-control" 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label>Senha</label>
          <input 
            type="password" 
            name="password" 
            className="form-control" 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label>Role</label>
          <select 
            name="role" 
            className="form-control" 
            onChange={handleChange}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Registrar</button>
      </form>
    </div>
  );
}

export default Register;
