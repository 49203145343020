import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  const instanceId = localStorage.getItem('instanceId');
  const instanceName = localStorage.getItem('instanceName');
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Permitir acesso à rota '/select-instance' mesmo sem 'instanceId'
  if (!instanceId && !instanceName && location.pathname !== '/select-instance') {
    return <Navigate to="/select-instance" state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;
